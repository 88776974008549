@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local('Inter'), url('../public/fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local('Inter'), url('../public/fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Inter'), url('../public/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Inter'), url('../public/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Inter'), url('../public/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Inter'), url('../public/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Inter'), url('../public/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local('Inter'), url('../public/fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('Inter'), url('../public/fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 100;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 200;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 300;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 500;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 600;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 700;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 800;
  src: local('JetBrains Mono'), url('../public/fonts/JetBrainsMono-ExtraBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', "JetBrains Mono",
  monospace;
}